var bodyHeight = 0;

$(document).ready(function() {
    setTimeout(function() {
        initContent();
    }, 100);
});

function initContent() {
    bodyHeight = document.body.clientHeight;
    $('.fontawsome-icon').each(function() {
        const pfad = $(this).children().attr('src');
        $(this).load(pfad);
    });
    setTimeout(function() {
        parallaxGallery();
    }, 5000);
    parallax();
    parallaxFixed();
    window.addEventListener('scroll', function() {
        parallax();
        parallaxFixed();
    });
}


const header = document.getElementById('header');
function parallax() {
    const moveTop = window.scrollY * 0.3;
    header.style.transform = 'translate3d(0px, ' + moveTop + 'px, 0px)';
}

const gridContainer = document.getElementsByClassName('grid-container');
let hoeheBeige = 100;

function isTouchDevice() {
    return (('ontouchstart' in window) ||
        (navigator.maxTouchPoints > 0) ||
        (navigator.msMaxTouchPoints > 0));
}
if(isTouchDevice()) {
    hoeheBeige = 0;
}
function parallaxFixed() {
    const scrollTop = window.scrollY;
    bodyHeight = document.body.clientHeight;
    Array.prototype.forEach.call(gridContainer, function (container) {
        const containerOffsetTop = container.offsetTop;
        if((containerOffsetTop + container.clientHeight +  366 ) > bodyHeight) {
            return false;
        }

        const offset = scrollTop - containerOffsetTop + hoeheBeige;

        const containerBottom = containerOffsetTop + container.clientHeight;
        const isContainerAtTop = scrollTop > (containerOffsetTop - hoeheBeige);

        if (isContainerAtTop && scrollTop < containerBottom) {
            applyOffset(container, offset);
        } else {
            applyOffset(container, 0)
        }
    });
}

function applyOffset(container, offset) {
    container.style.transform = `translate3d(0px, ${offset}px, 0px)`;
    const tmpContainer = container.getElementsByClassName('container');
    Array.prototype.forEach.call(tmpContainer, function(ct) {
        ct.style.transform = `translate3d(0px, ${-1 * offset}px, 0px)`;
    });
}


function parallaxGallery() {
    var objekte = document.getElementsByClassName('parallax-gallery-inner');

    Array.prototype.forEach.call(objekte, function(el) {
        var bilder = el.children;
        var i = 1;
        var max = bilder.length;
        var firstEl = '';
        var nextOn = false;
        Array.prototype.forEach.call(bilder, function(bild) {
            if(firstEl == '') {
                firstEl = bild;
            }
            if(nextOn == true) {
                bild.classList.add('active');
                nextOn = false;
            } else {
                if(bild.classList.contains('active') && i < max) {
                    bild.classList.remove('active');
                    nextOn = true;
                } else if(bild.classList.contains('active') && i >= max && max > 1) {
                    firstEl.classList.add('active');
                    bild.classList.remove('active');
                }
            }
            i++;
        });
    });

    setTimeout(function() {
        parallaxGallery();
    }, 5000);
}